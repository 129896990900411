import React, { useState, Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArtistCard from "../components/artist-card"
import { Container, CardDeck } from "react-bootstrap"
import dillowayImg from "../images/dilloway.png"
import youngImg from "../images/claudeyoung.png"
import kampireImg from "../images/kampire.png"
import rudimanImg from "../images/rudiman.png"

const ArtistsPage = () => (
    <Layout pageInfo={{ pageName: "artists" }}>
        <SEO title="Artists" />

        <CardDeck
            style={{ margin: 'auto' }}
        >

            <div className="artist-card-wrapper">
                <ArtistCard
                    img={rudimanImg}
                    name="SHAWN RUDIMAN"
                    className="d-flex"
                >
                    <div>
                        <p className='artist-date-heading'>
                            SUNDAY April 12th :: Nightlight
                    </p>
                        <p className="artist-description">
                            Rudiman is a Pittsburgh-based hardware techno producer and label owner.
                    </p>
                        <p className="artist-description">
                            Rudiman developed an early love for synth-pop, punk, and industrial music matched by a fascination with sci-fi synthesizer sounds. Learning synth and sequencer skills with childhood friend Ed Vargo, the duo THD (Total Harmonic Distortion) formed in 1989 — releasing several full-lengths, compilation tracks, and remixes of EBM-style industrial electro until 1997. From here, Rudiman delved head-on into Detroit techno production, heavily influenced by a love of techno held by his friend Trevor Combee (The Instigator). Rudiman joined Combee in running HyperVinyl, a Pittsburgh storefront and brief label run with several Rudiman releases.
                    </p>
                        <p className="artist-description">
                            His recorded material can be found on revered labels like 7th City, Matrix, Detroit Techno Militia (Rudiman remains a member of this grassroots electronic collective), Integrated Recordings, Tresor, and his own Technoir Audio output. His elaborately-stacked live sets still take him to leading techno clubs and festivals worldwide, cementing him as an unassuming legend in his world.
                    </p>
                    </div>
                </ArtistCard>
            </div>

            <div className="artist-card-wrapper">
                <ArtistCard
                    img={kampireImg}
                    name="KAMPIRE"
                    className="d-flex"
                >
                    <div>
                        <p className='artist-date-heading'>
                            THURSDAY April 9th :: Nightlight
                    </p>
                        <p className="artist-description">
                            The Zambian-born, Ugandan-raised DJ has cultivated a following with her highly energetic bass-heavy sets and limitless genre-shifting; a Kampire set can move you through Afro-house, Congolese soukos, Latin funk, Angolan kuduro, soca, and many more styles covering the globe. Kampire is also a member of Nyege Nyege, a Ugandan arts collective exploring East African music that has blossomed into two record labels and an annual music festival, described by many as one of the best and most innovative electronic festivals the world offers.
                    </p>
                        <p className="artist-description">
                            Hers is a whirlwind story of beginnings as a DJ just a short four years ago, to being a highly-sought spinner traveling the world over to share her craft. Named one of the Mixmag Top Ten Breakthrough DJs of 2018, her set at that year’s Nyege Nyege festival in Jinja, Uganda was broadcast on Boiler Room and met with massive enthusiastic response. She has played sets at widely attended events like Sonar Festival, Over The Border, Unsound, Red Bull Music, and Paraíso. Her DJ mixes have been featured on ​Resident Advisor​, ​Dekmantel​, ​Fact Magazine​, and she completed a Rinse FM radio residency in 2019.

                    </p>
                        <p className="artist-description">
                            Kampire is also an activist, writer (often focusing on contemporary African culture/art), and co-founder of “​Salooni​,” a multidisciplinary art project which explores “black hair practices as systems of knowledge through which culture and survivalist strategies are passed from generation to generation.”

                    </p>
                        <p className="artist-description">
                            Kampire is the headlining DJ at the <strong>NO VISA</strong>, a ‘no borders’ dance party and new fixture at Nightlight quickly cementing its place as a wildly innovative and successful collective.
        NO VISA has teamed up with Rise From Your Grave and will be presenting its party with Kampire on Thursday April 9th at Nightlight.

                    </p>

                    </div>
                </ArtistCard>
            </div>

            <div className="artist-card-wrapper">
                <ArtistCard
                    img={dillowayImg}
                    name="AARON DILLOWAY"
                    className="d-flex"
                >
                    <div>
                        <p className='artist-date-heading'>
                            FRIDAY April 10th :: Local 506.
                    </p>
                        <p className="artist-description">
                            Dilloway is an Ohio-based artist, owner of experimental label Hanson Records (est. 1994), and pillar in the American noise / underground communities.</p>
                        <p className="artist-description">
                            Dilloway’s countless recordings and live sets are a mesmerism of manipulated eight-track tapes with rhythmic looping, field recordings, feedback noise, synthesizers, and the oft-centerpiece of a contact microphone in his mouth — generating wild, distorted vocals ranging from stretches of eerie unsettlement to and cathartic theatrical moments of piercing screams.

                        </p>
                        <p className="artist-description">
                            With beginnings in experimental bands in the Michigan scene, Dilloway gained wider notoriety joining Wolf Eyes in 1997. Releasing solo efforts concurrently, Dilloway evolved with the band’s shift from industrial-influenced noise-punk to notorious free-form noise experimentation, until his departure from the band in 2004. After a temporary relocation to Nepal (collecting and releasing a breadth of Nepalese pop and field / radio recordings), Dilloway settled in Oberlin, Ohio and the experimental web built around Cleveland. Here, his Hanson label — an outlet for much of his own work and acts like Nautical Almanac, Emeralds, Kevin Drumm — grew into a storefront and wider record mail-order service. In the 2010s he recorded new contemporary classics like The Modern Jester and The Gag File — joining his discography of hundreds of releases under his own name and multiple aliases since the mid-1990s. Dilloway’s collaborations with other artists are equally vast; a small handful of names include Jason Lescaleet, Bill Nace, Lucrecia Dalt, Robert Turman, Genesis P-Orridge, John Wiese.

                        </p>
                        <p className="artist-description">
                            Dilloway’s performances — equally compelling, perplexing, and playful — have propelled him into the forefront of noise improvisation, and his set comes highly anticipated.

                        </p>
                    </div>

                </ArtistCard>
            </div>
            <div className="artist-card-wrapper">
                <ArtistCard
                    img={youngImg}
                    name="CLAUDE YOUNG JR."
                    className="d-flex"
                >
                    <div>
                        <p className='artist-date-heading'>
                            SATURDAY April 11th :: Nightlight.
                    </p>
                        <p className="artist-description">
                            Our Saturday headliner is a legend in the wellspring of techno producers, DJs, and sound designers to emerge from his native Detroit ! ! !
                    </p>
                        <p className="artist-description">
                            As the son of the influential WJLB radio founder / producer / personality, Young Jr. began early honing his mixing skills and quickly developed a name as a forward-thinking DJ and producer in his own right. With a strong improvisation and melodic ear guided by the sonically commanding influences of Jeff Mills and Robert Hood, Young made permanent waves with his timeless 1996 DJ-Kicks mix, productions on revered imprints like Elypsia, Djax-Up-Beats, Acacia and Axis Records (a rare guest release on the Jeff Mills-helmed label), and many artist remixes over his 3+ decade career including Carl Craig, Joey Beltram, Inner City, and Octave One. A move to the UK in 1995 launched Young’s near-decade of hugely successful international DJ touring.
                    </p>
                        <p className="artist-description">
                            Young has also been a force behind the labels Frictional, 7th City, and Dow/Utensil -- an early champion of Detroit innovators Walt J and Terrence Dixon. During life in the 2000s in Europe and Japan, Young founded cynet:media - a collaborative set to further challenge the constraints of electronic music - and Different World, a live production/mixing duo with Japanese producer and DJ Takasi Nakajima. His lengthy career of mixes and production work remain fixtures and influences in their own right in the contemporary techno world.
                    </p>
                    </div>
                </ArtistCard>
            </div>
        </CardDeck>
    </Layout >
)

export default ArtistsPage



