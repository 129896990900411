
import React, { useState } from "react"

import { Button, Modal } from "react-bootstrap"
import Card from 'react-bootstrap/Card'

const ArtistCard = (props) => {
    const [open, setOpen] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    return (
        <div>
            <Card
                {...props}
                style={{ width: '18rem' }}
                className="artist-card"
            >
                <Card.Img variant="top" src={props.img} />
                <Card.Body>
                    <Card.Title className="text-center">
                        <Button
                            onClick={() => setModalShow(!modalShow)}
                            aria-controls="artist-description"
                            aria-expanded={modalShow}
                            className="artist-card-btn"
                        >
                            {props.name}
                        </Button>
                    </Card.Title>

                    {/* <Collapse in={open}>
                    <Card.Text className="overflow-scroll">
                        <div>{props.children}</div>
                        <Modal.Dialog>


                        </Modal.Dialog>
                    </Card.Text>
                </Collapse> */}
                </Card.Body>
            </Card>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                scrollable={true}
                className="artist-modal-wrapper"
            >
                <Modal.Dialog className="modal-dialog-centered modal-dialog-scrollable">
                    <Modal.Header closeButton
                    >
                        <Modal.Title className="text-muted">{props.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                    >
                        {props.children}
                    </Modal.Body>

                </Modal.Dialog>

            </Modal>
        </div>
    )
}



export default ArtistCard


const modalStyles = {
    borderRadius: '0px',
    backgroundColor: 'black',
    boxShadow: '5px 5px 10px rgba(255,255,255,0.3)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContetn: 'center',
}
